import { RAFCustomFilter } from "../../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";

export class ListRequest {
  Skip?: number;
  Take?: number;
  Sort?: string[];
  ContainsText?: string;
  ContainsField?: string;
  Criteria?: any[];
  EqualityFilter?: any;
  IncludeDeleted?: boolean;
  ExcludeTotalCount?: boolean;

  IncludeColumns?: string[];
  ExcludeColumns?: string[];
  DistinctFields?: string[];

  //ColumnSelection?: any;
  ExportColumns?: string[];

  //CustomFilter?: RAFCustomFilter;
}

export class ActivityListServiceRequest extends ListRequest {
  RelatedToUID?: string;
  EntityName?: string;
  UserUID?: string;
  SecondaryRelatedToUID?: string;
  SecondaryRelatedToType?: string;
}

export class RetrieveRequest {
  EntityId?: object;
  IncludeColumns?: string[];
  ExcludeColumns?: string[];
  //ColumnSelection?: any;
  ExportColumns?: string[];
}

export class RelatedListRequest extends ListRequest {
  RelatedField?: string;
  RelatedFieldValue?: string;
  SecondaryRelatedField?: string;
  SecondaryRelatedFieldValue?: string;
}

export class ListServiceRequest extends ListRequest {
  CustomFilter?: RAFCustomFilter;
  ViewUID?: string;
  ViewName?: string;
  ReportUID?: string;
  EntityUID?: string;
  EntityName?: string;
}

export class AuditListRequest extends ListRequest {
  RelatedToUID?: string;
  PrimaryRelatedToUID?: string;
  CustomFilter?: RAFCustomFilter;
}

export class Sort {
  Field?: string;
  Descending?: boolean;
}

export class ListTenantServiceRequest extends ListServiceRequest {
  TenantUID?: string;
}

export class EntityListRequest extends ListRequest {
  EntityName?: string;
  EntityUID?: string;
  ParentEntityName?: string;
  viewUID?: string;
  IncludeSystemFields?: boolean;
  ExcludeMasterDetails?: boolean;
}

export class ViewListRequest extends ListRequest {
  EntityName?: string;
  EntityUID?: string;
  ParentEntityName?: string;
  ViewName?: string;
  viewUID?: string;
  IncludeSystemView?: boolean;
  viewType?: string;
  UserUID?: string;
}

export class EmailAttachmentDownload {
  EmailUID?: string;
  AttachmentUID?: string;
}

export class FocusServiceRequest extends ListRequest {
  FocusUserUID?: string;
  FocusUser?: string;
  RelatedToUID?: string;
  RelatedTo?: string;
  RelatedToType?: string;
  FocusOrder?: number;
}

export class ModuleListRequest extends ListRequest {
  EntityName?: string;
  EntityType?: string;
  EntityUID?: string;
  ModuleName?: string;
  ModuleUID?: string;
  IsChild?: boolean;
}

export class PageListRequest extends ListRequest {
  EntityName?: string;
  EntityUID?: string;
  PageType?: string;
}

export class PageRetrieveRequest extends RetrieveRequest {
  EntityName?: string;
  EntityUID?: string;
  PageType?: string;
}

export class ShiftVariantRequest extends RetrieveRequest {
  StartDate?: Date;
  EndDate?: Date;
  ServiceContractItem?: string;
  ServiceContractItemUID?: string;
  ShiftType?: string;
}

export class SaveRequest {
  EntityId?: object;
  Entity?: any;
  Localizations?: any;
}

export class RAFDataSaveRequest extends SaveRequest {
  ImportUID?: string;
  EntityUID?: string;
  CheckUpdate?: boolean;
}

export class TenantUserRequest {
  TenantUID?: string;
  UserEmail?: any;
}

export class PageRelatedListRequest extends PageRetrieveRequest {
  PageUID?: string;
}

export class DocumentPreSignedUrlRequest {
  Documents?: PreSignedUrl[];
  Thumnail?: boolean;
}

export class PreSignedUrl {
  UID?: string;
  FileName?: string;
  MimeType?: string;
  Entity?: string;
}

export class FollowRequest {
  EntityId?: string;
  EntityUID?: string;
  EntityName?: string;
  Followers?: string[];
}

export class FeedListRequest extends ListRequest {
  Skip?: number;
  Take?: number;

  UserUID?: string;
  RelatedToUID?: string;
  SecondaryRelatedToUID?: string;
  CommentType?: string;
}

export class ServiceScheduleListRequest extends ListRequest {
  ServiceContractItemUID?: string;
}
